import { Component } from '@angular/core';
import { AccessFormComponent } from "./components/access-form/access-form.component";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [AccessFormComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  production = environment.production;
  nome = environment.nome;

}
