<mat-toolbar class="example-toolbar d-flex justify-content-between">
  <div class="d-flex">
    <span class="material-symbols-outlined me-3" (click)="sidenav.toggle()">
      menu
    </span>
    <div>
      <img src="assets/logo.svg" alt="logo" class="logo" width="150px"/>
    </div>
  </div>

  <!-- Menubar com o Menu de Navegação -->
  <p-menubar [model]="items" *ngIf="items"></p-menubar>
</mat-toolbar>


  <mat-drawer-container class="example-container">
    <mat-drawer #sidenav mode="side" opened class="shadow">
      <app-navbar></app-navbar>
    </mat-drawer>
    <mat-drawer-content class="top-shadow">
      <div class="scrollable-content">
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
</mat-drawer-container>