<div class="container">
  <h1 class="mb-5 titulo">{{ isEditMode ? 'Editar Artigo' : 'Novo Artigo' }}</h1>

  <form [formGroup]="articleForm" (ngSubmit)="onSubmit()">

    <!-- Título -->
    <div class="form-group mb-3">
      <label for="titulo">Título</label>
      <input id="titulo" type="text" class="form-control" formControlName="titulo"
        placeholder="Digite o título do artigo" required>
    </div>

    <!-- Resumo -->
    <div class="form-group mb-3">
      <label for="resumo">Resumo</label>
      <textarea id="resumo" class="form-control" formControlName="resumo" rows="3" placeholder="Resumo do artigo"
        required></textarea>
    </div>

    <div class="row">
      <!-- Imagem Capa -->
      <div class="form-group mb-3 col-6">
        <label for="imagemCapa">Imagem de Capa</label>
        <input id="imagemCapa" type="file" class="form-control" (change)="onFileSelect($event, 'imagemCapa')">
      </div>

      <!-- Imagem Principal -->
      <div class="form-group mb-3 col-6">
        <label for="imagemPrincipal">Imagem Principal</label>
        <input id="imagemPrincipal" type="file" class="form-control" (change)="onFileSelect($event, 'imagemPrincipal')">
      </div>
    </div>

    <!-- Data -->
    <div class="form-group mb-3">
      <label for="data">Data</label>
      <input id="data" type="date" class="form-control" formControlName="data" required>
    </div>

    <!-- Autor -->
    <div class="form-group mb-3">
      <label for="autorId">Autor</label>
      <select id="autorId" class="form-control" formControlName="autorId" required>
        <option *ngFor="let autor of autores" [value]="autor.id">{{ autor.nome }}</option>
      </select>
    </div>

    <hr>

    <div formArrayName="itens">
      <div *ngFor="let item of itemControls.controls; let i = index" [formGroupName]="i" class="mb-3">
        <p><strong>Parágrafo {{i + 1}}</strong></p>
        <div class="form-group">
          <label for="tipo">Escolha o tipo</label>
          <select formControlName="tipo" class="form-control mb-2" (change)="onTipoChange($event, item)">
            <option [value]="0">Texto</option>
            <option [value]="1">Imagem</option>
          </select>
        </div>


        <div *ngIf="item.get('tipo')?.value === 0" class="form-group">
          <label for="texto">Digite o texto</label>
          <textarea formControlName="texto" class="form-control" rows="5" placeholder="Texto do parágrafo"></textarea>
        </div>

        <div *ngIf="item.get('tipo')?.value === 1" class="form-group">
          <label for="imagem">Realize upload da imagem</label>
          <input type="file" class="form-control" (change)="onFileSelect($event, 'imagem', i)">
        </div>

        <button type="button" class="btn btn-danger mt-3" (click)="removeItem(i)">Remover Parágrafo</button>
        <hr>
      </div>
    </div>

    <div class="d-flex justify-content-end mt-4">
      <button type="button" class="btn btn-secondary me-3" (click)="addItem()">Adicionar Parágrafo</button>
      <button type="submit" class="btn btn-primary">{{ isEditMode ? 'Salvar alterações' : 'Cadastrar artigo' }}</button>
    </div>
  </form>
</div>


<pre>Artigo: {{ articleForm.getRawValue() | json }}</pre>