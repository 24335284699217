<div class="container">
  <h2 class="titulo" *ngIf="isEditMode">Editar Usuário</h2>
  <h2 class="titulo" *ngIf="!isEditMode">Cadastrar Usuário</h2>
  <div class="form-group mb-3 col-4">
    <!-- Exibe o avatar se o link estiver presente ou a imagem em base64 -->
    <div class="avatar-container" (click)="triggerFileInput()">
        <img *ngIf="userForm.get('avatar')?.value; else avatarBase64" 
             [src]="userForm.get('avatar')?.value" 
             class="avatar-img" alt="Avatar">
        <!-- Exibe a imagem em base64 enquanto o arquivo está sendo carregado -->
        <ng-template #avatarBase64>
            <img *ngIf="userForm.get('avatarBase64')?.value" [src]="userForm.get('avatarBase64')?.value" class="avatar-img" alt="AvatarBase64">
        </ng-template>
        <!-- Ícone quando não há avatar -->
        <i *ngIf="!userForm.get('avatar')?.value && !userForm.get('avatarBase64')?.value" class="pi pi-user avatar-icon"></i>
    </div>

    <!-- Input de Arquivo Invisível -->
    <input #fileInput type="file" style="display: none;" (change)="onFileSelect($event)" />
  </div>
  <form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="mt-5">
    <div class="form-group">
      <label for="nome">Nome</label>
      <input id="nome" formControlName="nome"
        [ngClass]="{'is-invalid': userForm.get('nome')?.invalid && userForm.get('nome')?.touched}" class="form-control"
        required />
      <div class="invalid-feedback" *ngIf="userForm.get('nome')?.invalid && userForm.get('nome')?.touched"
        class="error">
        Nome é obrigatório.
      </div>
    </div>

    <div class="form-group mt-2">
      <label for="email">Email</label>
      <input id="email" class="form-control"
        [ngClass]="{'is-invalid': userForm.get('nome')?.invalid && userForm.get('nome')?.touched}"
        formControlName="email" required />
      <div class="invalid-feedback" *ngIf="userForm.get('email')?.invalid && userForm.get('email')?.touched"
        class="error">
        Informe um email válido.
      </div>
    </div>

    <div class="d-flex justify-content-end mt-3">
      <div>
        <button type="submit" class="button_primary pe-4 ps-4 pt-2 pb-2">{{isEditMode ? "Salvar alterações": "Cadastrar
          usuário"}}</button>
      </div>
    </div>
  </form>
</div>


<pre>Usuário: {{ usuario | json }}</pre>