<div class="container">
    <div class="d-flex justify-content-between">
        <h1 class="mb-5 titulo">
            Usuários cadastrados
        </h1>
        <div>
            <button class="button_primary pt-2 pb-2 pe-4 ps-4" [routerLink]="['/home/users/new']" routerLinkActive="router-link-active">Novo usuario</button>
        </div>
    </div>

    <p-table
        [value]="Userslist"
        [lazy]="true"
        selectionMode="single"
        [tableStyle]="{ 'min-width': '300px' }"
    >
        <ng-template pTemplate="header">
            <tr>
                <th style="width:25%">Nome Completo</th>
                <th style="width:25%">Email</th>
                <th style="width:10%">Status</th>
                <th style="width:30%">Funcionalidades</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-user>
            <tr>
                <td>{{ user.nome }}</td>
                <td>{{ user.email }}</td>
                <td>
                    <p-inputSwitch
                        [(ngModel)]="user.ativo" 
                        (onChange)="toggleStatus(user)"
                    ></p-inputSwitch>
                </td>
                <td>
                    <mat-icon class="icon" (click)="editUsers(user.id)">edit</mat-icon>
                    <mat-icon class="icon" (click)="deleteUsers(user.id)">delete</mat-icon>
                </td>  
            </tr>
        </ng-template>
    </p-table>
</div>