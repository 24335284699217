<div class="me-2 ms-2 mt-3">
  <div class="card flex justify-content-center">
    <p-tree
      [value]="files"
      class="w-full md:w-30rem"
      selectionMode="single"
      [(selection)]="selectedFile"
      (onNodeSelect)="onNodeSelect($event)"
      [ngClass]="{'active-node': selectedFile}"
      [expandedIcon]="'pi pi-fw pi-folder-open'"
      [collapsedIcon]="'pi pi-fw pi-folder'"
    />

  </div>
</div>
