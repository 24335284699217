import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { StudentsComponent } from './pages/students/students.component';
import { ArticlesComponent } from './pages/articles/articles.component';
import { authGuard } from './shared/guard/auth.guard';
import { FormArticleComponent } from './pages/articles/form-article/form-article.component';
import { FormStudentComponent } from './pages/students/form-student/form-student.component';
import { ListArticleComponent } from './pages/articles/list-article/list-article.component';
import { ListStudentComponent } from './pages/students/list-student/list-student.component';
import { UsersComponent } from './pages/users/users.component';
import { ListUserComponent } from './pages/users/list-user/list-user.component';
import { FormUserComponent } from './pages/users/form-user/form-user.component';

export const routes: Routes = [
    {
        path: 'auth',
        component: LoginComponent
    },
    {
        path: 'home',
        component: LayoutComponent,
        canActivate: [authGuard],
        children: [
            {
                path: 'students',
                component: StudentsComponent,
                children: [
                    {
                        path: 'list',
                        component: ListStudentComponent
                    },
                    {
                        path: 'new',
                        component: FormStudentComponent
                    },
                    {
                        path: 'edit/:id',
                        component: FormStudentComponent
                    },
                    {
                        path: '',
                        redirectTo: 'list',
                        pathMatch: 'full'
                    }
                ]
            },
            {
                path: 'articles',
                component: ArticlesComponent,
                children: [
                    {
                        path: 'list',
                        component: ListArticleComponent
                    },
                    {
                        path: 'new',
                        component: FormArticleComponent
                    },
                    {
                        path: 'edit/:id',
                        component: FormArticleComponent
                    },
                    {
                        path: '',
                        redirectTo: 'list',
                        pathMatch: 'full'
                    }
                ]
            },   
            {
                path: 'users',
                component: UsersComponent,
                children: [
                    {
                        path: 'list',
                        component: ListUserComponent
                    },
                    {
                        path: 'new',
                        component: FormUserComponent
                    },
                    {
                        path: 'edit/:id',
                        component: FormUserComponent
                    },
                    {
                        path: '',
                        redirectTo: 'list',
                        pathMatch: 'full'
                    }
                ]
            },
            {
                path: 'profile',
                component: FormUserComponent,
            },
            {
                path: '',
                redirectTo: 'articles',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    }
];
