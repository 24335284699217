import { Component, OnInit } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { NavbarComponent } from '../../components/navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from '../../shared/services/auth/auth.service';
import { UsersService } from '../../shared/services/users/users.service';
import { MatMenuModule } from '@angular/material/menu';
import { MenubarModule } from 'primeng/menubar';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [MenubarModule, RouterLink, MatToolbarModule, MatMenuModule, MatIconModule, RouterOutlet, MatSidenavModule, NavbarComponent, CommonModule],
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  opened!: boolean;
  userName: string = '';
  userAvatar: string = '';
  userId: string | null = null;
  items: MenuItem[] | undefined;

  constructor(private authService: AuthService, private userService: UsersService, private router: Router) {}

  ngOnInit(): void {
    const userInfo = this.authService.getUserInfo(); // Pegando informações do usuário
    if (userInfo?.usuario.usuarioId) {
      this.userId = userInfo.usuario.usuarioId;
      // Chama o serviço para buscar os dados do usuário com base no ID
      this.userService.getUsersById(this.userId).subscribe(userData => {
        this.userName = userData.nome;  // Nome do usuário
        this.userAvatar = userData.avatar;  // Avatar do usuário
      });
    }

    // Definindo os itens do menu de navegação, substituindo "Projects" pelo nome do usuário
    this.items = [
      {
        label: this.userName || 'Nome do usuário',  // Nome do usuário ou 'Projects' como fallback
        items: [
          { label: 'Editar Perfil', icon: 'pi pi-pencil', command: () => this.navigateToProfile() }, // Opção "Editar Perfil"
          { label: 'Sair', icon: 'pi pi-sign-out', command: () => this.logout() } // Opção "Sair"
        ]
      }
    ];
  }

  logout(): void {
    this.authService.logout();  
    // Remover o ID do localStorage
    localStorage.removeItem('userId');
  }

  navigateToProfile(): void {
    this.router.navigate(['/home/profile']);
  }
}
