import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../../shared/services/users/users.service';
import { AuthService } from '../../../shared/services/auth/auth.service';


@Component({
  selector: 'app-user-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './form-user.component.html',
  styleUrls: ['./form-user.component.scss']
})
export class FormUserComponent implements OnInit {
  userForm: FormGroup;
  userId: string | null = null;
  isEditMode: boolean = false;
  isProfileEdit: boolean = false; // Identifica se é a rota de perfil
  usuario: any;
  avatarBase64: string | null = null;

  constructor(
    private fb: FormBuilder,
    private userService: UsersService,
    private authService: AuthService, // Adicione o AuthService
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.userForm = this.fb.group({
      id: [null],
      nome: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      avatar: [null],
      avatarBase64: [null],
      ativo: [true]
    });
  }

  ngOnInit(): void {
    this.isProfileEdit = this.router.url === '/profile';

    if (this.isProfileEdit) {
      this.isEditMode = true;
      const loggedUser = this.authService.getUserInfo()?.usuario;
      if (loggedUser) {
        this.userForm.patchValue(loggedUser);
      }
    } else {
      this.userId = this.route.snapshot.paramMap.get('id');
      if (this.userId) {
        this.isEditMode = true;
        this.loadUser(this.userId);
      }
    }
  }

  private loadUser(userId: string): void {
    this.userService.getUsersById(userId).subscribe(user => {
      this.usuario = user;
      this.userForm.patchValue({
        ...user
      });
    });
  }

  // Função para abrir o input de arquivo
  triggerFileInput(): void {
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    fileInput?.click();
  }


   // Método chamado ao selecionar um arquivo
   onFileSelect(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.previewImage(file);  // Chama a função para exibir a imagem antes de enviar
      this.convertToBase64(file);  // Converte o arquivo para Base64
    }
  }

  // Função para exibir a imagem antes de enviar ao backend
  previewImage(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.avatarBase64 = reader.result as string;  // Armazena o Base64 para exibir
    };
    reader.readAsDataURL(file);
  }

  // Função para converter o arquivo para Base64
  convertToBase64(file: File): void {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Result = reader.result as string;
      // Verifica se a string já contém o prefixo 'data:image', caso contrário, adiciona-o
      if (!base64Result.startsWith('data:image')) {
        this.userForm.get('avatarBase64')?.setValue(`data:image/jpeg;base64,${base64Result}`);
      } else {
        this.userForm.get('avatarBase64')?.setValue(base64Result);
      }
    };
    reader.readAsDataURL(file);
  }

  async onSubmit(): Promise<void> {
    if (this.userForm.valid) {
      const userData = { ...this.userForm.value };

      if (this.isEditMode) {
        await this.userService.putUser(userData.id, userData).toPromise();
        Swal.fire('Sucesso!', 'Usuário editado com sucesso.', 'success');
      } else {
        await this.userService.postUser(userData).toPromise();
        Swal.fire('Sucesso!', 'Usuário cadastrado com sucesso.', 'success');
      }

      this.router.navigate(['/usuarios']);
    }
  }
}
