<div class="div-form">
    <div class="d-flex justify-content-center">
        <img src="assets/logo.svg" alt="logo" class="logo" width="35%" />
    </div>
    <div class="mt-4">
        <h1>
            Entre na sua conta
        </h1>
        <p>
            Informe seu email e senha
        </p>
    </div>
    <div>
        <form [formGroup]="formGroup" (ngSubmit)="cadastrar()">
            <div class="input">
                <label for="email">Email</label>
                <input type="email" id="email" class="form-control" formControlName="email" placeholder="Digite seu email" matInput [ngClass]="{'is-invalid': formGroup.get('email')?.touched && formGroup.get('email')?.invalid}" />
            </div>
            <div class="input">
                <label for="password">Senha</label>
                <input type="password" id="password" class="form-control" formControlName="senha" placeholder="Digite sua senha" matInput [ngClass]="{'is-invalid': formGroup.get('senha')?.touched && formGroup.get('senha')?.invalid}"/>
            </div>
            <div class="mt-3">
                <button type="submit" class="button_primary">Entrar</button>     
            </div>
        </form>
    </div>
</div>