<div class="div-login">
    <div class="row login">
        <div class="col-md-7">
            <div class="row ms-5 me-5">
                <div class="col-md-6 div-text">

                    <pre>production: {{production}}</pre>
                    <pre>nome: {{nome}}</pre>
                    <pre>teste: 01</pre>

                    <h1>Olá!</h1>
                    <h2>Bem vindo,</h2>
                    <h3>ao novo Portal Administrativo do Sistema Almob</h3>
                </div>
                <div class="col-md-6">
                    <img src="assets/login.png" alt="logo" class="logo" width="100%" />
                </div>
            </div>
        </div>
        <div class="col-12 col-md-5">
            <div class="div-form container">
                <app-access-form></app-access-form>
            </div>
        </div>
    </div>
</div>