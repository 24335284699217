import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { TreeModule } from 'primeng/tree';
import { filter } from 'rxjs';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [TreeModule, RouterLink],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NavbarComponent {
  selectedFile!: TreeNode;

  // files: TreeNode[] = [
  //   {
  //     key: '0',
  //     label: 'Artigos',
  //     icon: 'pi pi-fw pi-file',
  //     data: { route: '/home/articles/list' },
  //     children: [
  //       {
  //         key: '0-0',
  //         label: 'Cadastrar Artigo',
  //         icon: 'pi pi-fw pi-plus',
  //         data: { route: '/home/articles/new' },
  //       },
  //     ],
  //   },
  //   {
  //     key: '1',
  //     label: 'Alunos',
  //     icon: 'pi pi-fw pi-users',
  //     data: { route: '/home/students/list' },
  //     children: [
  //       {
  //         key: '1-0',
  //         label: 'Cadastrar Aluno',
  //         icon: 'pi pi-fw pi-user-plus',
  //         data: { route: '/home/students/new' },
  //       },
  //     ],
  //   },
  //   {
  //     key: '2',
  //     label: 'Usuários',
  //     icon: 'pi pi-id-card',
  //     data: { route: '/home/users/list' },
  //     children: [
  //       {
  //         key: '1-0',
  //         label: 'Cadastrar Usuário',
  //         icon: 'pi pi-fw pi-user-plus',
  //         data: { route: '/home/users/new' },
  //       },
  //     ],
  //   },
  // ];

  files: TreeNode[] = [
    {
      key: '1',
      label: 'Sistema',
      children: [
        {
          key: '1-0',
          label: 'Usuários',
          icon: 'pi pi-id-card',
          data: { route: '/home/users/list' },
        },
      ],
    },
    {
      key: '0',
      label: 'WebSite',
      children: [
        {
          key: '6-0',
          label: 'Contatos',
          icon: 'pi pi-fw pi-users',
          data: { route: '/home/students/list' },
        },
        {
          key: '7-0',
          label: 'Newsletters',
          icon: 'pi pi-fw pi-users',
          data: { route: '/home/students/list' },
        },
        {
          key: '1-0',
          label: 'Artigos',
          icon: 'pi pi-fw pi-file',
          data: { route: '/home/articles/list' },
        },
        {
          key: '2-0',
          label: 'Alunos',
          icon: 'pi pi-fw pi-file',
          data: { route: '/home/students/list' },
        },
        {
          key: '3-0',
          label: 'Cidades',
          icon: 'pi pi-fw pi-file',
          data: { route: '/home/students/list' },
        },
        {
          key: '4-0',
          label: 'Estados',
          icon: 'pi pi-fw pi-file',
          data: { route: '/home/students/list' },
        },
        {
          key: '5-0',
          label: 'Turmas',
          icon: 'pi pi-fw pi-file',
          data: { route: '/home/students/list' },
        }
      ],
    },
    {
      key: '2',
      label: 'Cadastros',
      children: [
        {
          key: '1-0',
          label: 'Empresas',
          icon: 'pi pi-fw pi-file',
          data: { route: '/home/aaaaa/list' },
        },
        {
          key: '1-0',
          label: 'Faturas',
          icon: 'pi pi-fw pi-file',
          data: { route: '/home/aaaaa/list' },
        }
      ],
    },
    {
      key: '2',
      label: 'Relatórios',
      children: [
        {
          key: '1-0',
          label: 'Relatório 01',
          icon: 'pi pi-fw pi-file',
          data: { route: '/home/aaaaa/list' },
        },
        {
          key: '1-0',
          label: 'Relatório 02',
          icon: 'pi pi-fw pi-file',
          data: { route: '/home/aaaaa/list' },
        },
        {
          key: '1-0',
          label: 'Relatório 03',
          icon: 'pi pi-fw pi-file',
          data: { route: '/home/aaaaa/list' },
        },
      ],
    },
  ];

  constructor(private router: Router) {}

  ngOnInit() {
    // Acompanhamento das mudanças na rota para destacar o item ativo
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.highlightActiveNode(this.router.url);
      });
  }

  onNodeSelect(event: any) {
    const node = event.node;

    // Verifica se o nó tem filhos e alterna a expansão
    if (node.children) {
      node.expanded = !node.expanded;
    }

    // Navega para a rota armazenada, se existir
    if (node.data?.route) {
      console.log('Navigating to:', node.data.route);
      this.router.navigate([node.data.route]);
    }
  }

  highlightActiveNode(currentRoute: string) {
    this.files.forEach((node) => {
      this.deselectNodes(node);
      this.selectNodeByRoute(node, currentRoute);
    });
  }

  deselectNodes(node: TreeNode) {
    node.children?.forEach((child) => {
      child.expanded = false;
      this.deselectNodes(child);
    });
  }

  selectNodeByRoute(node: TreeNode, currentRoute: string) {
    // Verifica se a rota do nó corresponde à rota atual
    if (node.data?.route === currentRoute) {
      this.selectedFile = node; // Define o nó como ativo
      node.expanded = true; // Expande o nó se a rota corresponder
    } else if (node.children) {
      let isActive = false; // Variável para verificar se um filho está ativo
      node.children.forEach((child) => {
        this.selectNodeByRoute(child, currentRoute); // Recursivamente percorre os filhos
        if (child.data?.route === currentRoute) {
          isActive = true; // Marca que o filho está ativo
        }
      });
      node.expanded = isActive; // Expande o nó pai se um filho estiver ativo
      if (isActive) {
        this.selectedFile = node; // Se um filho está ativo, marque o pai como selecionado
      }
    }
  }
}
