<div class="container">
    <h1 class="mb-3 titulo">{{ isEditMode ? 'Editar Aluno' : 'Novo Aluno' }}</h1>
    <div class="form-group mb-3 col-4">
        <!-- Exibe o avatar se o link estiver presente ou a imagem em base64 -->
        <div class="avatar-container" (click)="triggerFileInput()">
            <img *ngIf="studentForm.get('avatar')?.value; else avatarBase64" 
                 [src]="studentForm.get('avatar')?.value" 
                 class="avatar-img" alt="Avatar">
            <!-- Exibe a imagem em base64 enquanto o arquivo está sendo carregado -->
            <ng-template #avatarBase64>
                <img *ngIf="studentForm.get('avatarBase64')?.value" [src]="studentForm.get('avatarBase64')?.value" class="avatar-img" alt="AvatarBase64">
            </ng-template>
            <!-- Ícone quando não há avatar -->
            <i *ngIf="!studentForm.get('avatar')?.value && !studentForm.get('avatarBase64')?.value" class="pi pi-user avatar-icon"></i>
        </div>

        <!-- Input de Arquivo Invisível -->
        <input #fileInput type="file" style="display: none;" (change)="onFileSelect($event)" />
    </div>
    <form [formGroup]="studentForm" (ngSubmit)="onSubmit()">
        <!-- Nome -->
        <div class="form-group mb-3">
            <label for="nome">Nome</label>
            <input id="nome" type="text" class="form-control" formControlName="nome" 
                   [ngClass]="{'is-invalid': studentForm.get('nome')?.invalid && studentForm.get('nome')?.touched}" 
                   placeholder="Digite o nome do estudante">
            <div class="invalid-feedback" *ngIf="studentForm.get('nome')?.invalid && studentForm.get('nome')?.touched">
                Nome é obrigatório.
            </div>
        </div>

        <div class="row">
            <!-- CPF -->
            <div class="form-group mb-3 col-4">
                <label for="cpf">CPF</label>
                <input id="cpf" type="text" class="form-control" formControlName="cpf" 
                       [ngClass]="{'is-invalid': studentForm.get('cpf')?.invalid && studentForm.get('cpf')?.touched}" 
                       placeholder="Digite o CPF">
                <div class="invalid-feedback" *ngIf="studentForm.get('cpf')?.invalid && studentForm.get('cpf')?.touched">
                    CPF é obrigatório.
                </div>
            </div>

            <!-- Data de Nascimento -->
            <div class="form-group mb-3 col-4">
                <label for="dataNascimento">Data de Nascimento</label>
                <input id="dataNascimento" type="date" class="form-control" formControlName="dataNascimento"
                       [ngClass]="{'is-invalid': studentForm.get('dataNascimento')?.invalid && studentForm.get('dataNascimento')?.touched}">
                <div class="invalid-feedback" *ngIf="studentForm.get('dataNascimento')?.invalid && studentForm.get('dataNascimento')?.touched">
                    Data de nascimento é obrigatória.
                </div>
            </div>

            <!-- Turma -->
            <div class="form-group mb-3 col-4">
                <label for="turmaId">Selecione a turma</label>
                <select id="turmaId" class="form-control" formControlName="turmaId"
                        [ngClass]="{'is-invalid': studentForm.get('turmaId')?.invalid && studentForm.get('turmaId')?.touched}">
                    <option *ngFor="let turma of turmas" [value]="turma.id">{{ turma.fim | date:'dd/MM/yyy' }}</option>
                </select>
                <div class="invalid-feedback" *ngIf="studentForm.get('turmaId')?.invalid && studentForm.get('turmaId')?.touched">
                    Turma é obrigatória.
                </div>
            </div>
        </div>
        
        <div class="row">
            <!-- Telefone -->
            <div class="form-group mb-3 col-4">
                <label for="telefone">Telefone</label>
                <input id="telefone" type="text" class="form-control" formControlName="telefone"
                       [ngClass]="{'is-invalid': studentForm.get('telefone')?.invalid && studentForm.get('telefone')?.touched}"
                       placeholder="Digite o telefone">
                <div class="invalid-feedback" *ngIf="studentForm.get('telefone')?.invalid && studentForm.get('telefone')?.touched">
                    Telefone é obrigatório.
                </div>
            </div>

            <!-- Email -->
            <div class="form-group mb-3 col-4">
                <label for="email">Email</label>
                <input id="email" type="email" class="form-control" formControlName="email"
                       [ngClass]="{'is-invalid': studentForm.get('email')?.invalid && studentForm.get('email')?.touched}"
                       placeholder="Digite o email">
                <div class="invalid-feedback" *ngIf="studentForm.get('email')?.invalid && studentForm.get('email')?.touched">
                    Email inválido.
                </div>
            </div>

            <!-- Cidade -->
            <div class="form-group mb-3 col-4">
                <label for="cidadeId">Selecione a cidade</label>
                <select id="cidadeId" class="form-control" formControlName="cidadeId"
                        [ngClass]="{'is-invalid': studentForm.get('cidadeId')?.invalid && studentForm.get('cidadeId')?.touched}">
                    <option *ngFor="let cidade of cidades" [value]="cidade.id">{{ cidade.nome }}</option>
                </select>
                <div class="invalid-feedback" *ngIf="studentForm.get('cidadeId')?.invalid && studentForm.get('cidadeId')?.touched">
                    Cidade é obrigatória.
                </div>
            </div>
        </div>

        <div class="row">
            <!-- LinkedIn -->
            <div class="form-group mb-3 col-12">
                <label for="linkedIn">LinkedIn</label>
                <input id="linkedIn" type="text" class="form-control" formControlName="linkedIn"
                       [ngClass]="{'is-invalid': studentForm.get('linkedIn')?.invalid && studentForm.get('linkedIn')?.touched}"
                       placeholder="Perfil LinkedIn">
            </div>
        </div>

        <!-- Submit -->
        <div class="d-flex justify-content-end align-items-center">
            <div>
                <button type="submit" class="btn button_primary pe-4 ps-4 pt-2 pb-2">{{ isEditMode ? 'Salvar alterações' : 'Cadastrar aluno' }}</button>
            </div>
        </div>
    </form>
</div>

<pre>estudante: {{ estudante | json }}</pre>

<!-- <pre>turmas: {{ turmas | json }}</pre> -->

<!-- <pre>cidades: {{ cidades | json }}</pre> -->

