<div class="container">
    <div class="d-flex justify-content-between">
        <h1 class="mb-5 titulo">
            Alunos cadastrados
        </h1>
        <div>
            <button class="button_primary pt-2 pb-2 pe-4 ps-4" [routerLink]="['/home/students/new']" routerLinkActive="router-link-active" >Novo aluno</button>      
        </div>  
    </div>
    <p-table
        [value]="studentslist"
        [rows]="rows"
        [totalRecords]="total"
        [paginator]="true"
        [lazy]="true"
        selectionMode="single"
        (onPage)="pageChange($event)"
        [tableStyle]="{ 'min-width': '300px' }"
        [rowsPerPageOptions]="[5, 10, 20]"
    >
        <ng-template pTemplate="header">
            <tr>
                <th style="width:20%">Nome Completo</th>
                <th style="width:10%">CPF</th>
                <th style="width:20%">Email</th>
                <th style="width:20%">Data de Nascimento</th>
                <th style="width:10%">Status</th>
                <th style="width:20%">Funcionalidades</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-student>
            <tr>
                <td>{{ student.nome }}</td>
                <td>{{ student.cpf }}</td>
                <td>{{ student.email }}</td>
                <td>{{ formatarData(student.dataNascimento) }}</td>
                <td>
                    <p-inputSwitch
                        [(ngModel)]="student.ativo" 
                        (onChange)="toggleStatus(student)"
                    ></p-inputSwitch>
                </td>
                <td class="d-flex">
                    <mat-icon class="icon" (click)="editStudent(student.id)">edit</mat-icon>
                    <mat-icon class="icon" (click)="deleteStudent(student.id)">delete</mat-icon>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
